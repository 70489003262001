import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function ChildrenProductAvtokresla() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const brands = filtersAll.filter((item) => item.type === 'brandCarSeats')
  const groupChild = filtersAll.filter((item) => item.type === 'groupChild')
  const setFasteningCChild = filtersAll.filter(
    (item) => item.type === 'setFasteningCChild',
  )
  return (
    <PageWrapper
      isLinks={true}
      categoryRoute={null}
      columnRoute={'detskie'}
      subCategoryRoute={'avtokresla'}
      category={category}
      categoryId={1}
      columnsId={4}
      subCategoryId={50}
      title={'Верхняя одежда'}
      subTitle={'Детский гардероб'}
      isBrands={true}
      brands={brands}
      isGroupChild={true}
      groupChild={groupChild}
      isSetFasteningCChild={true}
      setFasteningCChild={setFasteningCChild}
    />
  )
}
export default ChildrenProductAvtokresla

import React from 'react'
import { useSelector } from 'react-redux'
import PageWrapper from '../../../components/PageWrapper'

function ChildrenProductZdoroveIUhod() {
  const category = useSelector((state) => state.app.category)
  const filtersAll = useSelector((state) => state.filtersIt.filtersAll)
  const type = filtersAll.filter((item) => item.type === 51)

  return (
    <PageWrapper
      isLinks={true}
      categoryRoute={null}
      columnRoute={'detskie'}
      subCategoryRoute={'zdorove-i-uhod'}
      category={category}
      categoryId={1}
      columnsId={4}
      subCategoryId={51}
      title={'Верхняя одежда'}
      subTitle={'Детский гардероб'}
      isAksessuary={true}
      aksessuary={type}
    />
  )
}
export default ChildrenProductZdoroveIUhod
